import React from 'react';
import './DataTableTop30.css';

// This component will render 30 stock ( in list top RS 30 stock) in to a table
const DataTableTop30 = ({ data }) => {
    // Determine the data to display (all if <= 30, first 30 if > 30)
    const displayData = data.length > 30 ? data.slice(0, 30) : data;
  
    // Split data into chunks of 6 for each row
    const rows = [];
    for (let i = 0; i < displayData.length; i += 6) {
      rows.push(displayData.slice(i, i + 6));
    }
  
    return (
        <div className="table-container">
            <table className="half-width-table"  >
                <thead>
                <tr>
                    <th colSpan="6">Top 30 Stocks</th>
                </tr>
                </thead>
                <tbody>
                {rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                        <td key={cellIndex}>{cell}</td>
                    ))}
                    {/* Fill remaining cells if row length is less than 6 */}
                    {row.length < 6 &&
                        Array.from({ length: 6 - row.length }).map((_, idx) => (
                        <td key={`empty-${idx}`}></td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
            
            <div>
                {/* <p>Đây là 30 cổ phiếu </p> */}
            </div>

        </div>
    );
  };

export default DataTableTop30;