import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import './Form.css'

const Form1 = () => {
    return (  
        <Container fluid className='subscription_form'>
            <div className='formBox col-md-4'>
                <Form className='col-md-12'>
                    <Form.Text>
                        <h4>Đăng ký ngay để được tư vấn</h4>
                    </Form.Text>
                    <Form.Control className="mb-3 mt-3" type="text" placeholder="Họ và tên" /> 
                    <Form.Control className="mb-3" type="number" placeholder="Số điện thoại" />
                    <Form.Control className="mb-3" type="email" placeholder="Enter email" />
                    
                    <Button variant="success" type="submit" className='col-md-12 mb-3' style={{backgroundColor:'#4CBB17', border: 'none'}}>
                        Đăng ký
                    </Button>
                </Form>
            </div>
        </Container>

    );
}
 
export default Form1;