import React from 'react';
import { Container } from 'react-bootstrap';
import NavBar1 from '../../Homepage/NavBar/NavBar';

import './styles.css'
import no1 from '../../../media/course/no1.jpg';
import no2 from '../../../media/course/no2.jpg';


const Courses = () => {
    return (  
        <div>
            <NavBar1 />

            <Container>
                <h2 className='fw-bold mt-4'>KHOÁ HỌC</h2>
                {/* Khoá đầu tư thật */}
                <div className='col-12 col-md-6 my-4'>
                    <h3>ĐẦU TƯ THẬT</h3>
                    <p className='fw-bold fs-5 mb-0'>Phần lý thuyết</p>
                    <p>Bao gồm tất cả lý thuyết nhà đầu tư cần trang bị khi tham gia đầu tư trên thị trường</p>
                    <p className='fw-bold fs-5 mb-0'>Phần thực chiến</p>  
                    <p>Thực chiến phân tích và cơ hội đầu tư trên thị trường chứng khoán Việt Nam</p>
                </div>

                {/* Khoá ProTrade 20 */}
                <div className='col-12 col-md-6 offset-md-6 my-4'>
                    <h3>PRO TRADE 20</h3>
                    <div className='course_detail_line'>
                        {/* <img src={no1} alt='1' style={{width: '60px'}}/> */}
                        <h3>Nội dung</h3>
                    </div>
                    <li>Phân tích cơ bản
                            <p>Cách đọc và phân tích BCTC dưới góc độ là 1 nhà đầu tư từ đó nhận diện được các doanh nghiệp có tiềm năng tăng trưởng giá trị nội tại</p>
                    </li>

                    <li>Phân tích kỹ thuật
                            <ul id='investor_course'>
                                <li>Cách xác định xu hướng giá</li>
                                <li>Cách sử dụng khối lượng để xác đỉnh vùng đỉnh và đáy của cổ phiếu</li>
                                <li>Nhà đầu tư lớn "bẫy" nhà đầu tư nhỏ lẻ như thế nào?</li>
                                <li>Cách lựa chọn các cổ phiếu có xu hướng "khoẻ", chuẩn bị bước vào giai đoạn tăng nhanh và mạnh với tiềm năng tăng trưởng 20-25% trong 6-8 tuần</li>
                                <li>Công thức điểm mua "sớm"</li>
                            </ul>
                    </li>
                    <h3>Thời lượng</h3>
                    <ul>
                        <li>3 Buổi online về phân tích cơ bản, đọc hiểu báo cáo tài chính</li>
                        <li>3 buổi online về nền tảng phân tích kỹ thuật</li>
                        <li>3 buổi coaching offline về phân tích kỹ thuật chuyên sâu theo phương pháp của True Invest</li>
                        <li>6 tháng thực hành và đầu tư thực chiến cùng True Invest</li>
                    </ul>
                </div>

                {/* Khoá Investor */}
                <div className='col-12 col-md-6 my-4'>
                    <h3>INVESTOR</h3>
                    <p>Nội dung</p>
                    <ul>
                        <li>Cách đọc và phân tích BCTC dưới góc độ là 1 nhà đầu tư</li>
                        <li>Phân tích mô hình kinh doanh của các ngành nghề cụ thể ở Việt Nam</li>
                        <li>Phân tích lợi thế cạnh tranh của doanh nghiệp</li>
                        <li>6 đặc điểm chung của những cổ phiếu tăng giá trong dài hạn (đầu tư tăng trưởng)</li>
                        <li>Phương pháp tìm ra những công ty quá rẻ so với giá trị nội tại (Đầu tư giá trị)</li>
                        <li>Xác định vùng giá hợp lý của cổ phiếu (cách định giá cổ phiếu)</li>
                    </ul>

                    <p>Thời lượng</p>
                    <ul>
                        <li>3 buổi online chuyên sâu về phân tích BCTC</li>
                        <li>3 buổi online về phương pháp đầu tư hưởng cổ tức</li>
                        <li>
                            4 buổi coaching offline chuyên sâu về phân tích cơ bản < br/>
                            <ul id='investor_course'>
                                <li>Đầu tư tăng trưởng</li>
                                <li>Đầu tư giá trị</li>
                                <li>Cách định giá cổ phiếu</li>
                                <li>Thực hành</li>
                            </ul>

                        </li>
                        <li>2 buổi offline về tư duy và phương pháp đầu tư chiến lược vào 1 doanh nghiệp (niêm yết hoặc không niêm yết)</li>
                        <li>Thực chiến và đầu tư cùng True Invest trọn đời</li>
                    </ul>
                </div>
            </Container>
        </div>
    );
}
 
export default Courses;