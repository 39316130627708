import React from "react";

// This component will render Strategy Table ( Nhip dap thi truong)
const StrategyTable = ({ data }) => {
    // Fixed labels for the first column
    const labels = [
      "Diễn biến thị trường",
      "Xu hướng thị trường",
      "Thời gian",
      "Chiến lược hành động",
      "Tỷ trọng giải ngân"
    ];
  
    // Ensure the data does not exceed the number of labels
    const displayData = data.slice(0, labels.length);
  
    return (
      <table border="1" className="full-width-table">
        <thead>
          <tr>
            <th>Thị trường</th>
            <th>Nội dung</th>
          </tr>
        </thead>
        <tbody>
          {labels.map((label, index) => (
            <tr key={index} >
              <td>{label}</td>
              <td>{displayData[index] || ''}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

export default StrategyTable;