import React from 'react';
import { BsYoutube } from 'react-icons/bs';
import Container from 'react-bootstrap/esm/Container';

import ti_logo from '../../../media/logo_contact.png'

import './styles.css'

const Contacts = () => {
    return (  
        <Container fluid className='contact'>
            <div className='logo col-4 col-md-4'>
                <img 
                    src={ti_logo} 
                    alt="logo-contact" 
                    border="0" 
                    height='100px'
                    className='mt-5 pl-4'
                    
                />
            </div>

            <div className='info col-8 col-md-8 mt-5 mb-5'>
                <h5>Liên hệ với chúng tôi</h5>
                <p>
                    Địa chỉ: Tầng 4 - 08 Tràng Thi, Hoàn Kiếm, Hà Nội <br />
                    Hotline: 038.964.0081 <br/>
                    Email: trueinvest.vn@gmail.com <br/>
                    {/* Youtube icon */}
                    <BsYoutube className="text-danger"/>  
                    <a 
                        href='https://www.youtube.com/@auTuThatTrueInvest'
                        target="_blank"
                    > 
                        :  Theo dõi kênh Youtube của chúng tôi
                    </a> 
                    
                </p>
            </div>
        </Container>
    );
}
 
export default Contacts ;