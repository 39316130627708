import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route, Routes } from 'react-router-dom';
import './App.css';
import About from './components/About/About';
import Homepage from './components/Homepage/Homepage';
import Courses from './components/ProductPage/Course/Courses';
import TodayMarket from './components/TodayMarket/TodayMarket';

const router = createBrowserRouter(createRoutesFromElements(
  <Route>
    <Route path='/' element={ <Homepage/> } />
    <Route path='/about' element = { <About/>} />
    <Route path='/product/course' element= {<Courses/>}/>
    <Route path='/market' element = {<TodayMarket/>} />
  </Route>
));




function App() {
  return (
    <div className="App">
        <RouterProvider router={ router } />
    </div>
  );
}

export default App;
