import React from 'react';
import Container from 'react-bootstrap/esm/Container';

import './styles.css'
import Img1 from '../../../media/home/1.jpg';
import Img2 from '../../../media/home/2.jpg';
import Img3 from '../../../media/home/3.jpg';
import Img4 from '../../../media/home/4.jpg';

const Product = () => {
    return (  
        <Container>
            <div className='product-title'>
                <h3>SẢN PHẨM</h3>
            </div>
            <div className='products-list'>
                {/* Produc 1 */}
                <div className='col-6 col-md-3 product'>
                    <div className='product-img'>
                        <img src={Img1} alt='dich-vu-mg' />
                    </div>
                    <div className='product-item-title'>
                        <p>Dịch vụ môi giới chứng khoán</p>
                    </div>
                </div>

                <div className='col-6 col-md-3 product'>
                    <div className='product-img'>
                        <img src={Img2} alt='dich-vu-mg' /> 
                    </div>
                    <div className='product-item-title'>
                        <p>Ứng dụng Swing Trade</p>
                    </div>
                </div>

                <div className='col-6 col-md-3 product'>
                    <div className='product-img'>
                        <img src={Img3} alt='dich-vu-mg' /> 
                    </div>
                    <p>Quản lý tài khoản</p>
                </div>

                <div className='col-6 col-md-3 product'>
                    <div className='product-img'>
                        <img src={Img4} alt='dich-vu-mg' />
                    </div>
                    <p>Khoá học chứng khoán</p>
                </div>
            </div>
        </Container>
    );
}
 
export default Product;